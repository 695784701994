import axiosInstance from "../axiosInstance";
import { BASE_URL } from "../config";

// Fetch videos by folder
export const fetchVideosByFolder = async (folderId = null) => {
    try {
        const url = folderId === null
            ? `${BASE_URL}/api/videos/folder/root/`
            : `${BASE_URL}/api/videos/folder/${folderId}/`;
        const response = await axiosInstance.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching videos:", error);
        return [];
    }
};



// Fetch video details
export const fetchVideoDetails = async (videoId) => {
    console.log(videoId);

    try {
        const response = await axiosInstance.get(`${BASE_URL}/api/videos/video/${videoId}/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching video details:', error);
        throw error;
    }
};

// Fetch video bookmarks
export const fetchVideoBookmarks = async (videoId) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/api/videos/video/${videoId}/bookmarks/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching video bookmarks:', error);
        throw error;
    }
};

// Create a session
export const createSession = async (videoId) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/api/sessions/`, {
            video: videoId,
            session_date: new Date().toISOString(),
        });
        return response.data;
    } catch (error) {
        console.error('Error creating session:', error);
        throw error;
    }
};

// Send metrics to the backend
export const sendMetrics = async (sessionId, data) => {
    console.log(data);
    try {
        const response = await axiosInstance.post(`${BASE_URL}/api/snapshots/`, {
            session: sessionId,
            timestamp: Math.floor(data.timestamp), // Ensure timestamp is in seconds
            metrics: data.metrics,
        });
        return response.data;
    } catch (error) {
        console.error('Error sending metrics to backend:', error);
        throw error;
    }
};


// Upload video
export const uploadVideo = async (videoFile, folderId, videoTitle, categoryId, onUploadProgress) => {
    const formData = new FormData();
    formData.append('file', videoFile);
    formData.append('folder', folderId);
    formData.append('title', videoTitle);
    formData.append('category', categoryId);


    try {
        const response = await axiosInstance.post(`${BASE_URL}/api/videos/upload/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        });
        return response.data; // Return the server's response data
    } catch (error) {
        const serverMessage = error.response?.data?.error || error.response?.data?.message || 'An error occurred';
        throw new Error(serverMessage);
    }
};
