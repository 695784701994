import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/layouts/MainLayout";
import { fetchCategories } from "../../services/categoryService"; // Import the fetchCategories function
import { BASE_URL } from "../../config";

const CategorySelection = () => {
	const navigate = useNavigate(); // Initialize useNavigate
	const [categories, setCategories] = useState([]); // State to hold categories
	const [loading, setLoading] = useState(true); // State to show loading spinner
	const [error, setError] = useState(null); // State to handle errors

	// Fetch categories from the backend
	useEffect(() => {
		const loadCategories = async () => {
			try {
				const data = await fetchCategories(); // Use the service function
				setCategories(data);
			} catch (err) {
				setError("Failed to load categories.");
			} finally {
				setLoading(false);
			}
		};

		loadCategories();
	}, []); // Run only once when the component mounts

	// Handle card click
	const handleCardClick = (categoryId) => {
		// Navigate to the video-upload route with the category ID
		navigate(`/video-upload/${categoryId}`);
	};


	// Show loading spinner
	if (loading) {
		return (
			<MainLayout className="min-h-screen text-white flex flex-col items-center justify-center">
				<p>Loading categories...</p>
			</MainLayout>
		);
	}

	// Show error message if there's an issue
	if (error) {
		return (
			<MainLayout className="min-h-screen text-white flex flex-col items-center justify-center">
				<p>{error}</p>
			</MainLayout>
		);
	}

	return (
		<MainLayout className="min-h-screen text-white flex flex-col items-center p-6">
			<h2 className="text-2xl font-bold my-8">Select an Analysis Category</h2>

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl">
				{categories.map((category) => (
					<div
						key={category.id}
						onClick={() => handleCardClick(category.id)} // Pass ID instead of name
						className={`relative rounded-lg shadow-lg overflow-hidden cursor-pointer transform hover:scale-105 transition-transform`}
					>
						{/* Video Part */}
						{category.video ? (
							<video
								src={`${BASE_URL}/${category.video}`}
								className="w-full h-48 object-cover"
								autoPlay
								loop
								muted
							/>
						) : (
							<div className="w-full h-48 bg-gray-700 flex items-center justify-center text-white">
								<p>No Video Available</p>
							</div>
						)}
						{/* Overlay with Text */}
						<div className="absolute inset-0 bg-secondary bg-opacity-50 flex flex-col justify-end p-4">
							<h2 className="text-xl font-bold">{category.title}</h2>
							<p className="text-sm text-gray-300">{category.description}</p>
						</div>
					</div>
				))}
			</div>
		</MainLayout>
	);
};

export default CategorySelection;
