import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { fetchCategories } from "../services/categoryService";

const ProtectedCategoryRoute = ({ children }) => {
    const { categoryId } = useParams(); // `category` is the ID in this case
    const [isValidCategory, setIsValidCategory] = useState(null);

    useEffect(() => {
        const validateCategory = async () => {
            try {
                // Fetch categories and extract their IDs
                const categories = await fetchCategories();
                const categoryIds = categories.map((c) => c.id.toString()); // Convert IDs to strings for comparison
                // Check if the category ID exists in the list
                setIsValidCategory(categoryIds.includes(categoryId));
            } catch (error) {
                console.error("Error validating category:", error);
                setIsValidCategory(false);
            }
        };

        validateCategory();
    }, [categoryId]);

    if (isValidCategory === null) {
        return <p>Loading...</p>; // Or a loading spinner
    }

    if (!isValidCategory) {
        return <Navigate to="/video-upload" replace />; // Redirect to category selection page
    }

    return children;
};

export default ProtectedCategoryRoute;
