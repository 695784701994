import axiosInstance from '../axiosInstance';
import { BASE_URL } from '../config';

// Fetch maintenance mode status
export const fetchMaintenanceModeStatus = async () => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/api/maintenance-mode/`);
        return response.data.maintenance_mode;
    } catch (error) {
        console.error('Error fetching maintenance mode status:', error);
        throw error;
    }
};
