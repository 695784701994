import axiosInstance from '../axiosInstance';
import { BASE_URL } from '../config';

/**
 * Fetch all categories.
 * @returns {Promise} Promise object representing the API response.
 */
export const fetchCategories = async () => {
  const response = await axiosInstance.get(`${BASE_URL}/api/categories/`);
  return response.data;
};
