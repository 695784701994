import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  fetchBookmarks,
  createBookmark,
  updateBookmark,
  deleteBookmark,
} from '../../services/bookmarkService';
import NoteModal from '../modals/NoteModal';

const VideoContent = ({ isSmallScreen, videoSrc, videoRef, canvasRef, bookmarks, setBookmarks, StepsChart, videoId }) => {
  const { t } = useTranslation();
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [noteTime, setNoteTime] = useState(null);
  const [editingBookmarkId, setEditingBookmarkId] = useState(null); // Track if we're editing a bookmark
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);

  const openNoteModal = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      const currentTime = videoRef.current.currentTime;
      setNoteTime(currentTime);
      setNoteText('');
      setEditingBookmarkId(null);
      setNoteModalOpen(true);
    }
  };

  const openEditModal = (bookmark) => {
    setNoteTime(bookmark.timestamp);
    setNoteText(bookmark.notes);
    setEditingBookmarkId(bookmark.id);
    setNoteModalOpen(true);
  };

  const handleLoadedMetadata = () => {
    setIsMetadataLoaded(true);
  };

  const formatTime = (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substring(11, 19);
  };

  const saveNote = async () => {
    try {
      if (editingBookmarkId) {
        const updatedBookmark = await updateBookmark(videoId, editingBookmarkId, {
          timestamp: noteTime,
          notes: noteText,
        });
        setBookmarks(bookmarks.map((b) => (b.id === editingBookmarkId ? updatedBookmark : b)));
      } else {
        const newBookmark = await createBookmark(videoId, {
          timestamp: noteTime,
          notes: noteText,
        });
        setBookmarks([...bookmarks, newBookmark]);
      }
      setNoteModalOpen(false);
      setNoteText('');
    } catch (error) {
      console.error('Error saving note:', error.response?.data || error.message);
    }
  };

  const renderBookmarks = () => {
    if (!isMetadataLoaded || !videoRef.current || !videoRef.current.duration) return null;
    return bookmarks.map((bookmark) => {
      const position = (bookmark.timestamp / videoRef.current.duration) * 100;
      return (
        <div
          key={bookmark.id}
          onClick={() => openEditModal(bookmark)}
          className="absolute top-0 h-full w-[5px] bg-secondary cursor-pointer"
          style={{ left: `${position}%`, transform: 'translateY(-50%)' }}
          title={`Note: ${bookmark.notes}`}
        ></div>
      );
    });
  };

  const handleDeleteNote = async () => {
    try {
      if (editingBookmarkId) {
        await deleteBookmark(videoId, editingBookmarkId);
        setBookmarks(bookmarks.filter((b) => b.id !== editingBookmarkId));
        setNoteModalOpen(false);
      }
    } catch (error) {
      console.error('Error deleting note:', error.response?.data || error.message);
    }
  };

  return (
    <div className={`flex-1 w-full ${isSmallScreen ? 'ml-12' : 'ml-0'} ${isSmallScreen ? 'mr-[4.7rem]' : 'mr-0'}`}>
      <div className="px-5 mt-5">
        <div className="w-full flex flex-col justify-center items-center overflow-hidden">
          <div className="relative">
            {videoSrc ? (
              <video
                ref={videoRef}
                controls
                src={videoSrc}
                crossOrigin="anonymous"
                width="800"
                height="480"
                onLoadedMetadata={handleLoadedMetadata}
              >
                <source src={videoSrc} type="video/mp4" />
                {t('video_content.unsupported_format')}
              </video>
            ) : (
              <p className="text-white">{t('video_content.loading_video')}</p>
            )}
            <canvas
              ref={canvasRef}
              className="absolute top-0 left-0"
              width="800"
              height="480"
              style={{ pointerEvents: 'none' }}
            />

            <div className="absolute bottom-4 left-0 right-0 h-2 bg-transparent">{renderBookmarks()}</div>

            <button
              onClick={openNoteModal}
              className="absolute top-4 right-4 bg-white rounded-full p-2 shadow-md"
              title="Add Note"
              style={{ zIndex: 10 }}
            >
              <i className="fa fa-sticky-note fa-lg"></i>
            </button>
          </div>
        </div>

        <div className="card mt-4">
          <div className="mb-5">
            <i className="fa fa-chart-line me-2"></i>
            <span>{t('video_content.graphs')}</span>
          </div>
          <StepsChart />
        </div>
      </div>

      <NoteModal
        isOpen={noteModalOpen}
        onClose={() => setNoteModalOpen(false)}
        noteTime={noteTime}
        noteText={noteText}
        setNoteText={setNoteText}
        saveNote={saveNote}
        deleteNote={handleDeleteNote}
        editingBookmarkId={editingBookmarkId}
        formatTime={formatTime}
      />
    </div>
  );
};

export default VideoContent;
