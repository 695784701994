import axiosInstance from '../axiosInstance';
import { BASE_URL } from '../config';

// Fetch all runners
export const fetchRunners = async (page, pageSize) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/api/runners/?page=${page}&page_size=${pageSize}&delay=1`);
        return response.data;
    } catch (error) {
        console.error('Error fetching runners:', error);
        throw error;
    }
};


// Fetch runner details by ID
export const fetchRunnerById = async (runnerId) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/api/runners/${runnerId}/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching runner:', error);
        throw error;
    }
};

// Fetch folder for a runner by ID
export const fetchRunnerFolder = async (runnerId) => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/api/runners/${runnerId}/folders/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching runner folder:', error);
        throw error;
    }
};

// Delete a runner by ID
export const deleteRunner = async (runnerId) => {
    try {
        await axiosInstance.delete(`${BASE_URL}/api/runners/${runnerId}/`);
        return runnerId;
    } catch (error) {
        console.error('Error deleting runner:', error);
        throw error;
    }
};

// Create a new runner
export const createRunner = async (data) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/api/runners/`, data);
        return response.data;
    } catch (error) {
        console.error('Error creating runner:', error);
        throw error;
    }
};

// Update an existing runner
export const updateRunner = async (runnerId, data) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/api/runners/${runnerId}/`, data);
        return response.data;
    } catch (error) {
        console.error('Error updating runner:', error);
        throw error;
    }
};
