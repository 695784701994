import axiosInstance from '../axiosInstance';
import { BASE_URL } from '../config';

// Update profile settings
export const updateSettings = async (settings) => {
    try {
        const response = await axiosInstance.put(
            `${BASE_URL}/api/profile/update-settings/`,
            settings
        );
        return response.data;
    } catch (error) {
        console.error('Error updating settings:', error);
        throw error;
    }
};

// Delete account
export const deleteAccount = async () => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/api/delete-account/`);
        return response.status;
    } catch (error) {
        console.error('Error deleting account:', error);
        throw error;
    }
};
