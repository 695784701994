import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/ProtectedRoute';
import Layout from '../components/layouts/Layout';
import Home from '../pages/Home';
import PaymentPage from '../pages/payments/PaymentPage';
import SettingsPage from '../pages/settings/SettingsPage';
import EditProfilePage from '../pages/profile/EditProfilePage';
import UserProfilePage from '../pages/profile/UserProfilePage';
import RunnersListPage from '../pages/runners/RunnersListPage';
import MyAccount from '../pages/profile/MyAccount';
import VideoUpload from '../pages/video/VideoUpload';
import VideoAnalysis from '../pages/video/VideoAnalysis';
import History from '../pages/video/History';
import SessionAnalysis from '../pages/video/SessionAnalysis';
import CategorySelection from '../pages/video/CategorySelection';
import ProtectedCategoryRoute from './ProtectedCategoryRoute';

const ProtectedRoutes = [
    <Route
        key="home"
        path="/home"
        element={
            <ProtectedRoute>
                <Home />
            </ProtectedRoute>
        }
    />,
    <Route
        key="my-account"
        path="/my-account"
        element={
            <ProtectedRoute>
                <Layout>
                    <MyAccount />
                </Layout>
            </ProtectedRoute>
        }
    />,
    <Route
        key="user-profile"
        path="/user-profile"
        element={
            <ProtectedRoute>
                <Layout>
                    <UserProfilePage />
                </Layout>
            </ProtectedRoute>
        }
    />,
    <Route
        key="settings"
        path="/settings"
        element={
            <ProtectedRoute>
                <Layout>
                    <SettingsPage />
                </Layout>
            </ProtectedRoute>
        }
    />,
    <Route
        key="runners"
        path="/runners"
        element={
            <ProtectedRoute>
                <Layout>
                    <RunnersListPage />
                </Layout>
            </ProtectedRoute>
        }
    />,
    <Route
        key="payment"
        path="/payment"
        element={
            <ProtectedRoute>
                <Layout>
                    <PaymentPage />
                </Layout>
            </ProtectedRoute>
        }
    />,
    <Route
        key="edit-profile"
        path="/edit-profile"
        element={
            <ProtectedRoute>
                <Layout>
                    <EditProfilePage />
                </Layout>
            </ProtectedRoute>
        }
    />,
    <Route
        key="view-folder"
        path="/folder/:folderId"
        element={
        <ProtectedRoute>
            <Home />
        </ProtectedRoute>
        }
    />,
    <Route
        key="video-upload"
        path="/video-upload"
        element={
            <ProtectedRoute>
                <CategorySelection />
            </ProtectedRoute>
        }
    />,
    <Route
        key="video-upload-category"
        path="/video-upload/:categoryId"
        element={
            <ProtectedRoute>
                <ProtectedCategoryRoute>
                    <VideoUpload />
                </ProtectedCategoryRoute>
            </ProtectedRoute>
        }
    />,
    <Route
        key="video-analysis"
        path="/video-analysis/:videoId"
        element={
            <ProtectedRoute>
                <VideoAnalysis />
            </ProtectedRoute>
        }
    />,
    <Route
        key="history"
        path="/history/:videoId"
        element={
            <ProtectedRoute>
                <History />
            </ProtectedRoute>
        }
    />,
    <Route
        key="session-analysis"
        path="/session-analysis/:sessionId"
        element={
            <ProtectedRoute>
                <SessionAnalysis />
            </ProtectedRoute>
        }
    />,
];

export default ProtectedRoutes;
