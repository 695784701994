import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import FolderModal from '../modals/FolderModal';
import VideoItem from './VideoItem';
import FolderItem from './FolderItem';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../utils/ConfirmDialog';

function MediaExplorer({ folders, videos, onViewFolder, setFolders, setVideos }) {
    const navigate = useNavigate();
    const isFolderEmpty = folders.length === 0 && videos.length === 0;
    const { t } = useTranslation();

    const [activeVideoMenu, setActiveVideoMenu] = useState(null);
    const [activeFolderMenu, setActiveFolderMenu] = useState(null);

    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
    const [folderToRename, setFolderToRename] = useState(null);

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false); // For dialog state
    const [currentDeleteItem, setCurrentDeleteItem] = useState(null); // To track the item to delete

    const toggleVideoMenu = (id) => {
        setActiveVideoMenu((prevActiveMenu) => (prevActiveMenu === id ? null : id));
    };

    const toggleFolderMenu = (id) => {
        setActiveFolderMenu((prevActiveMenu) => (prevActiveMenu === id ? null : id));
    };

    const handleCreateOrRenameFolder = async (id, folderName) => {
        if (id) {
            try {
                await axiosInstance.put(`/api/folders/${id}/`, { name: folderName });
                onRenameSuccess(id, folderName);
            } catch (error) {
                console.error(t('folder_management.error_renaming_folder'), error);
            }
        }
    };

    const onRenameSuccess = (id, newName) => {
        setFolders((prevFolders) =>
            prevFolders.map((folder) =>
                folder.id === id ? { ...folder, name: newName } : folder
            )
        );
        closeModal();
    };

    const closeModal = () => {
        setIsFolderModalOpen(false);
        setFolderToRename(null);
    };

    const handleMenuItemClick = (action, id, type, event) => {
        event.stopPropagation();
        if (action === 'move_to_trash') {
            setCurrentDeleteItem({ id, type }); // Set item to delete
            setIsConfirmDialogOpen(true); // Open confirmation dialog
        } else if (action === 'rename' && type === 'folder') {
            const folder = folders.find(f => f.id === id);
            setFolderToRename(folder);
            setIsFolderModalOpen(true);
        }
        setActiveVideoMenu(null);
        setActiveFolderMenu(null);
    };
    const handleDelete = async () => {
        if (currentDeleteItem) {
            const { id, type } = currentDeleteItem;

            try {
                if (type === 'video') {
                    await axiosInstance.delete(`/api/videos/${id}/delete/`);
                    onDeleteSuccess('video', id);
                } else if (type === 'folder') {
                    await axiosInstance.delete(`/api/folders/${id}/delete/`);
                    onDeleteSuccess('folder', id);
                }
            } catch (error) {
                console.error(
                    type === 'video'
                        ? t('general.confirm_title')
                        : t('general.confirm_title'),
                    error
                );
            } finally {
                setIsConfirmDialogOpen(false); // Close dialog
                setCurrentDeleteItem(null);
            }
        }
    };

    // const handleDeleteVideo = async (id) => {
    //     const userConfirmed = window.confirm("Are you sure you want to delete this video?");

    //     if (!userConfirmed) {
    //         // User canceled the deletion
    //         return;
    //     }
    //     try {
    //         await axiosInstance.delete(`/api/videos/${id}/delete/`);
    //         onDeleteSuccess('video', id);
    //     } catch (error) {
    //         console.error(t('videos.error_deleting_video'), error);
    //     }
    // };

    // const handleDeleteFolder = async (id) => {
    //     const userConfirmed = window.confirm("Are you sure you want to delete this folder?");

    //     if (!userConfirmed) {
    //         // User canceled the deletion
    //         return;
    //     }
    //     try {
    //         await axiosInstance.delete(`/api/folders/${id}/delete/`);
    //         onDeleteSuccess('folder', id);
    //     } catch (error) {
    //         console.error(t('folder_management.error_deleting_folder'), error);
    //     }
    // };

    const onDeleteSuccess = (type, deletedId) => {
        if (type === 'video') {
            setVideos(prevVideos => prevVideos.filter(video => video.id !== deletedId));
        } else if (type === 'folder') {
            setFolders(prevFolders => prevFolders.filter(folder => folder.id !== deletedId));
        }
    };

    const personalFolders = folders.filter(folder => !folder.runner);
    const runnerFolders = folders.filter(folder => folder.runner);

    return (
        <div className="">
            {isFolderEmpty ? (
                <div className="text-center text-gray-500 col-span-full w-full">
                    {t('folder_management.folder_empty')}
                </div>
            ) : (
                <>
                    {videos.length > 0 && (
                        <div className="flex flex-wrap gap-4 mt-5">
                            {videos.map((video) => (
                                <VideoItem
                                    key={video.id}
                                    video={video}
                                    activeVideoMenu={activeVideoMenu}
                                    toggleVideoMenu={toggleVideoMenu}
                                    handleMenuItemClick={handleMenuItemClick}
                                />
                            ))}
                        </div>
                    )}
                    {personalFolders.length > 0 && (
                        <div className="mt-8">
                            <div className="flex flex-wrap gap-4 mt-5">
                                {personalFolders.map((folder) => (
                                    <FolderItem
                                        key={folder.id}
                                        folder={folder}
                                        activeFolderMenu={activeFolderMenu}
                                        toggleFolderMenu={toggleFolderMenu}
                                        handleMenuItemClick={handleMenuItemClick}
                                        onViewFolder={onViewFolder}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {/* {runnerFolders.length > 0 && (
                        <div className="mt-8">
                            <p className="text-xl font-semibold text-white">{t('runner.my_runners')}</p>
                            <div className="flex flex-wrap gap-4 mt-5">
                                {runnerFolders.map((folder) => (
                                    <FolderItem
                                        key={folder.id}
                                        folder={folder}
                                        activeFolderMenu={activeFolderMenu}
                                        toggleFolderMenu={toggleFolderMenu}
                                        handleMenuItemClick={handleMenuItemClick}
                                        onViewFolder={onViewFolder}
                                    />
                                ))}
                            </div>
                        </div>
                    )} */}
                </>
            )}
            {/* Confirm Dialog */}
            {isConfirmDialogOpen && (
                <ConfirmDialog
                    title={t('general.confirm_title')}
                    message={
                        currentDeleteItem?.type === 'video'
                            ? t('general.confirm_delete')
                            : t('general.confirm_delete')
                    }
                    onConfirm={handleDelete}
                    onCancel={() => setIsConfirmDialogOpen(false)}
                />
            )}

            <FolderModal
                isOpen={isFolderModalOpen}
                onClose={closeModal}
                onCreate={handleCreateOrRenameFolder}
                folder={folderToRename}
            />
        </div>
    );
}

export default MediaExplorer;
