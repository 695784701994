import axiosInstance from '../axiosInstance';

// Fetch sessions for a specific video
export const fetchSessionsByVideoId = async (videoId) => {
    try {
        const response = await axiosInstance.get(`/api/sessions/video/${videoId}/`);
        return response.data; // Return the session data
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Error fetching session history');
    }
};


// Fetch snapshots for a session
export const fetchSnapshotsBySessionId = async (sessionId) => {
    try {
        const response = await axiosInstance.get(`/api/sessions/${sessionId}/snapshots/`);
        return response.data; // Return snapshots data
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Error fetching snapshots');
    }
};

// Fetch bar chart data for a session
export const fetchBarChartDataBySessionId = async (sessionId) => {
    try {
        const response = await axiosInstance.get(`/api/sessions/${sessionId}/barchart-data/`);
        return response.data; // Return bar chart data
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Error fetching bar chart data');
    }
};
