import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { requestPasswordReset } from '../../services/authService';
import ActionButton from '../../components/utils/ActionButton';
import InputFieldLight from '../../components/utils/InputFieldLight';

const PasswordResetRequest = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');
        setMessage('');

        try {
            await requestPasswordReset(email);
            setMessage(t('authentication.reset_link_sent'));
        } catch (error) {
            setErrorMessage(error.message || t('authentication.error_sending_link'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 space-y-3 bg-white rounded-md shadow-md">
                <h2 className="text-2xl font-bold mb-6 text-center text-primaryAccent">
                    {t('authentication.forgot_password')}
                </h2>
                {message && <div className="text-primaryAccent">{message}</div>}
                {errorMessage && <div className="text-red-500">{errorMessage}</div>}
                <form onSubmit={handleForgotPassword}>
                    <div className="mb-4">
                        <InputFieldLight
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            autoFocus
                            placeholder={t('authentication.email')}
                        />
                    </div>
                    <div className="mt-6">
                        <ActionButton
                            type="submit"
                            label={loading ? t('authentication.sending') : t('authentication.send_reset_link')}
                            customStyles="w-full px-4 py-2 font-semibold focus:outline-none focus:ring-2 focus:ring-primaryAccent"
                            disabled={loading}
                        />
                    </div>
                </form>
                <div className="text-center">
                    <a href="/login" className="text-blue-500 hover:underline">
                        {t('authentication.back_to_login')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetRequest;
