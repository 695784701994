import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPasswordConfirm } from '../../services/authService'; // Import the service
import ActionButton from '../../components/utils/ActionButton';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import InputFieldLight from '../../components/utils/InputFieldLight';

function PasswordResetConfirm() {
    const { uidb64, token } = useParams();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            setIsLoading(false);
            return;
        }

        try {
            await resetPasswordConfirm(uidb64, token, newPassword, confirmPassword); // Use the service
            navigate('/login'); // Navigate to login page on success
        } catch (err) {
            setError(err.message); // Handle error
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl font-bold mb-6 text-center text-primaryAccent">Reset Password</h2>
                {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <InputFieldLight
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            autoFocus
                        />
                    </div>

                    <div className="mb-6">
                        <InputFieldLight
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>

                    <div className="flex items-center justify-between">
                        <ActionButton
                            type="submit"
                            label={isLoading ? <LoadingSpinner color="text-white" /> : 'Reset Password'}
                            onClick={handleSubmit}
                            customStyles={`w-full font-bold py-2 px-4 ${
                                isLoading ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            disabled={isLoading}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PasswordResetConfirm;
