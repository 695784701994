import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    fetchRunners,
    fetchRunnerById,
    fetchRunnerFolder,
    deleteRunner,
    createRunner,
    updateRunner,
} from '../../services/runnerService';
import ActionButton from '../../components/utils/ActionButton';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import RunnerModal from '../../components/modals/RunnerModal';
import PageContainer from '../../components/layouts/PageContainer';
import DataTable from 'react-data-table-component';

function RunnersListPage() {
    const { t } = useTranslation();
    const [runners, setRunners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingRunnerId, setEditingRunnerId] = useState(null);
    const [loadingRunnerId, setLoadingRunnerId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [formData, setFormData] = useState({
        name: '',
        height: '',
        weight: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        loadRunners();
    }, [currentPage, pageSize]);

    const loadRunners = async () => {
        setLoading(true);
        try {
            const data = await fetchRunners(currentPage, pageSize);
            setRunners(data.results); // Assuming the paginated response includes "results"
            setTotalRows(data.count); // Total number of rows from the API

        } catch (error) {
            console.error('Error loading runners:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPageSize) => {
        setPageSize(newPageSize);
    };

    const handleDeleteRunner = async (runnerId) => {
        const confirmed = window.confirm(t('runner.delete_confirm'));
        if (!confirmed) return;

        setLoadingRunnerId(runnerId);
        try {
            await deleteRunner(runnerId);
            setRunners((prevRunners) => prevRunners.filter((runner) => runner.id !== runnerId));
        } catch (error) {
            console.error(t('runner.delete_error'), error);
        } finally {
            setLoadingRunnerId(null);
        }
    };

    const handleEditRunner = async (runnerId) => {
        try {
            const runner = await fetchRunnerById(runnerId);
            setFormData({
                name: runner.name,
                height: runner.height,
                weight: runner.weight,
            });
            setIsEditing(true);
            setEditingRunnerId(runnerId);
            setIsModalOpen(true);
        } catch (error) {
            console.error(t('runner.edit_error'), error);
        }
    };

    const handleViewFolder = async (runnerId) => {
        try {
            const folder = await fetchRunnerFolder(runnerId);
            navigate(`/folder/${folder.id}`);
        } catch (error) {
            alert(t('runner.view_folder_not_found'));
            console.error('Error fetching folder:', error);
        }
    };

    const handleAddRunner = () => {
        setFormData({ name: '', height: '', weight: '' });
        setIsEditing(false);
        setIsModalOpen(true);
    };

    const handleSubmitRunner = async () => {
        try {
            if (isEditing) {
                const updatedRunner = await updateRunner(editingRunnerId, formData);
                setRunners((prevRunners) =>
                    prevRunners.map((runner) =>
                        runner.id === editingRunnerId ? updatedRunner : runner
                    )
                );
            } else {
                const newRunner = await createRunner(formData);
                setRunners((prevRunners) => [...prevRunners, newRunner]);
            }
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error saving runner:', error);
        }
    };

    const filteredRunners = runners.filter(
        (runner) =>
            runner.name &&
            runner.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            setFilterText('');
            setResetPaginationToggle(!resetPaginationToggle);
        };

        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', margin: '5px 0' }}>
                <input
                    type="text"
                    placeholder="Filter By Name"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    style={{
                        padding: '8px 12px',
                        fontSize: '14px',
                        width: '300px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                    }}
                />
                <ActionButton
                    onClick={handleClear}
                    label="X"
                    customStyles="bg-sky-500 hover:bg-sky-700"
                />

            </div>
        );
    }, [filterText, resetPaginationToggle]);

    const columns = [
        { name: t('runner.name'), selector: (row) => row.name, sortable: true },
        { name: t('runner.height'), selector: (row) => row.height, sortable: true },
        { name: t('runner.weight'), selector: (row) => row.weight, sortable: true },
        {
            name: t('runner.actions'),
            cell: (row) => (
                <div className="table-actions">
                    <ActionButton
                        label={t('runner.view_folders')}
                        onClick={() => handleViewFolder(row.id)}
                        customStyles="text-xs bg-sky-500 hover:bg-sky-700 mr-1"
                    />
                    <ActionButton
                        onClick={() => handleEditRunner(row.id)}
                        label={t('runner.edit')}
                        customStyles="text-xs bg-yellow-500 hover:bg-yellow-700 mr-1"
                    />
                    <ActionButton
                        onClick={() => handleDeleteRunner(row.id)}
                        label={
                            loadingRunnerId === row.id ? (
                                <LoadingSpinner size="small" />
                            ) : (
                                t('runner.delete')
                            )
                        }
                        customStyles=" text-xs bg-red-500 hover:bg-red-700"
                        disabled={loadingRunnerId === row.id}
                    />
                </div>
            ),
        },
    ];

    return (
        <PageContainer>
            <div className="bg-primary p-6 shadow-lg rounded-md">
                <h2 className="text-xl font-semibold text-white mb-4">{t('runner.my_runners')}</h2>
                <ActionButton
                    onClick={handleAddRunner}
                    label={t('runner.add_runner')}
                    iconClass="fas fa-running"
                    customStyles="bg-green-500 hover:bg-green-700 mb-4"
                />
                {loading ? (
                    <div className="flex justify-center items-center mt-4">
                        <LoadingSpinner />
                    </div>
                ) : (
                    <DataTable
                        columns={columns}
                        data={filteredRunners}
                        progressPending={loading}
                        progressComponent={<LoadingSpinner />}
                        customStyles={{
                            tableWrapper: {
                                style: {
                                    margin: '20px 0',
                                    overflowX: 'auto', // Add horizontal scroll for smaller screens
                                },
                            },
                            table: {
                                style: {
                                    minWidth: '1000px', // Ensure a minimum width for the table
                                },
                            },
                            cells: {
                                style: {
                                    wordWrap: 'break-word',
                                    whiteSpace: 'nowrap',
                                },
                            },
                        }}
                        theme="dark"
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationDefaultPage={currentPage}
                        paginationPerPage={pageSize} // Set the page size dynamically
                        paginationRowsPerPageOptions={[10, 20, 50, 100]} // Dropdown options
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange} // Connected handler
                        highlightOnHover
						striped={false}
						selectableRows
						onSelectedRowsChange={(state) =>
							console.log('Selected Rows:', state.selectedRows)
						}
						subHeader
						subHeaderComponent={subHeaderComponentMemo}
						persistTableHead
                    />
                )}
                <RunnerModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    formData={formData}
                    setFormData={setFormData}
                    handleSubmit={handleSubmitRunner}
                />
            </div>
        </PageContainer>
    );
}

export default RunnersListPage;
