import axiosInstance from '../axiosInstance';
import { BASE_URL } from '../config';

// Fetch countries
export const fetchCountries = async () => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/api/countries/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching countries:', error);
        throw error;
    }
};

// Update profile
export const updateProfile = async (profileData) => {
    try {
        const formData = new FormData();
        Object.keys(profileData).forEach((key) => {
            if (profileData[key] instanceof File || profileData[key] !== undefined) {
                formData.append(key, profileData[key]);
            }
        });

        const response = await axiosInstance.put(`${BASE_URL}/api/profile/update-profile/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating profile:', error);
        throw error;
    }
};



// Change user password
export const changePassword = async (oldPassword, newPassword) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/api/auth/change-password/`, {
            old_password: oldPassword,
            new_password: newPassword,
        });
        return response;
    } catch (error) {
        throw error; // Throw the error to be handled in the component
    }
};
