import React, { useEffect, useState } from 'react';
import { fetchMaintenanceModeStatus } from '../../services/maintenanceService';

const MaintenanceMode = ({ children }) => {
    const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkMaintenanceMode = async () => {
            try {
                const maintenanceStatus = await fetchMaintenanceModeStatus();
                setIsMaintenanceMode(maintenanceStatus);
            } catch (error) {
                console.error('Error checking maintenance mode:', error);
            } finally {
                setLoading(false);
            }
        };

        checkMaintenanceMode();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (isMaintenanceMode) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-100">
                <div className="text-center">
                    <h1 className="text-2xl font-bold text-red-500">
                        The application is currently under maintenance.
                    </h1>
                    <p className="text-gray-700 mt-2">
                        Please check back later. Thank you for your patience!
                    </p>
                </div>
            </div>
        );
    }

    return children;
};

export default MaintenanceMode;
