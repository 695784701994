// components/ActionButton.js
import React from 'react';

const ActionButton = ({ onClick, label, iconClass, customStyles, type = 'button' }) => {
  return (
    <button
      type={type} // Use the "type" prop (default is 'button', can be 'submit' or others)
      onClick={onClick}
      className={`${customStyles} rounded bg-primaryAccent px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primaryAccentHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryAccent`}
    >
      {iconClass && <i className={`${iconClass} mr-1`} />} {/* Conditionally render icon if provided */}
      {label}
    </button>
  );
};

export default ActionButton;
