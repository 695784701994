import axiosInstance from '../axiosInstance';
import { BASE_URL } from '../config';
/**
 * Fetch all bookmarks for a video.
 */
export const fetchBookmarks = async (videoId) => {
  const response = await axiosInstance.get(`${BASE_URL}/api/videos/video/${videoId}/bookmarks/`);
  return response.data;
};

/**
 * Create a new bookmark.
 */
export const createBookmark = async (videoId, data) => {
  const response = await axiosInstance.post(`${BASE_URL}/api/videos/video/${videoId}/bookmark/`, data);
  return response.data;
};

/**
 * Update an existing bookmark.
 */
export const updateBookmark = async (videoId, bookmarkId, data) => {
  const response = await axiosInstance.put(`${BASE_URL}/api/videos/video/${videoId}/bookmark/${bookmarkId}/`, data);
  return response.data;
};

/**
 * Delete a bookmark.
 */
export const deleteBookmark = async (videoId, bookmarkId) => {
  await axiosInstance.delete(`${BASE_URL}/api/videos/video/${videoId}/bookmark/${bookmarkId}/delete/`);
};
