import React, { useEffect, useState } from 'react';
import {
    fetchPaymentMethods,
    addPaymentMethod,
    deletePaymentMethod,
} from '../../services/paymentService'; // Import the service functions
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import ActionButton from '../../components/utils/ActionButton';
import PaymentMethodModal from '../../components/modals/PaymentMethodModal';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/layouts/PageContainer';

function PaymentPage() {
    const { t } = useTranslation();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(null);
    const [error, setError] = useState(null);
    const [serverError, setServerError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [setAsDefault, setSetAsDefault] = useState(false);
    const [errors, setErrors] = useState({});
    const [newPaymentMethod, setNewPaymentMethod] = useState({
        method_type: 'credit_card',
        name_on_card: '',
        card_number: '',
        expiry_date: '',
        cvv: '',
        bank_account_number: '',
        bank_name: '',
        routing_number: '',
        paypal_email: '',
    });

    // Fetch payment methods on component mount
    useEffect(() => {
        const loadPaymentMethods = async () => {
            try {
                const data = await fetchPaymentMethods();
                setPaymentMethods(data);
            } catch (err) {
                console.error(t('payments.fetch_error'), err);
                setError(t('payments.fetch_error'));
            } finally {
                setLoading(false);
            }
        };
        loadPaymentMethods();
    }, [t]);

    const handleAddPaymentMethod = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPaymentMethod({
            ...newPaymentMethod,
            [name]: value,
        });
    };

    const handleMethodTypeChange = (e) => {
        setNewPaymentMethod({
            method_type: e.target.value,
            name_on_card: '',
            card_number: '',
            expiry_date: '',
            cvv: '',
            bank_account_number: '',
            bank_name: '',
            routing_number: '',
            paypal_email: '',
        });
        setErrors({});
    };

    const handleSetAsDefaultChange = () => {
        setSetAsDefault(!setAsDefault);
    };

    const handleDeletePaymentMethod = async (methodId) => {
        if (!window.confirm(t('payments.delete_confirm'))) {
            return;
        }

        setIsDeleting(methodId);
        try {
            await deletePaymentMethod(methodId); // Use service
            setPaymentMethods((prevMethods) =>
                prevMethods.filter((method) => method.id !== methodId)
            );
            alert(t('general.delete_sucess_message'));
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.error || t('general.delete_failed');
                alert(errorMessage);
            } else {
                alert(t('general.unexpected_error'));
            }
        } finally {
            setIsDeleting(null);
        }
    };

    const handleSubmitPaymentMethod = async (e) => {
        e.preventDefault();
        let paymentData = { method_type: newPaymentMethod.method_type };
        const cleanCardNumber = newPaymentMethod.card_number.replace(/\s+/g, '');

        if (newPaymentMethod.method_type === 'credit_card') {
            paymentData = {
                ...paymentData,
                name_on_card: newPaymentMethod.name_on_card,
                card_number: cleanCardNumber,
                expiry_date: newPaymentMethod.expiry_date,
                cvv: newPaymentMethod.cvv,
            };
        } else if (newPaymentMethod.method_type === 'paypal') {
            paymentData = { ...paymentData, paypal_email: newPaymentMethod.paypal_email };
        } else if (newPaymentMethod.method_type === 'bank_transfer') {
            paymentData = {
                ...paymentData,
                bank_account_number: newPaymentMethod.bank_account_number,
                bank_name: newPaymentMethod.bank_name,
                routing_number: newPaymentMethod.routing_number,
            };
        }
        paymentData.is_default = setAsDefault;

        try {
            await addPaymentMethod(paymentData); // Use service
            const updatedPaymentMethods = await fetchPaymentMethods(); // Reload payment methods
            setPaymentMethods(updatedPaymentMethods);
            setNewPaymentMethod({
                method_type: 'credit_card',
                name_on_card: '',
                card_number: '',
                expiry_date: '',
                cvv: '',
                bank_account_number: '',
                bank_name: '',
                routing_number: '',
                paypal_email: '',
            });
            setSetAsDefault(false);
            setIsModalOpen(false);
        } catch (error) {
            if (error.response && error.response.data) {
                setServerError(error.response.data);
            } else {
                console.error(t('payments.add_error'), error);
            }
        }
    };

    const formatMethodType = (methodType) => {
        return methodType
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    if (loading) {
        return (
            <div className="bg-secondary p-6 shadow-lg rounded-md">
                <h2 className="text-xl font-semibold text-white">{t('payments.payment_options')}</h2>
                <div className="flex justify-center items-center mt-4">
                    <LoadingSpinner />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-secondary p-6 shadow-lg rounded-md">
                <h2 className="text-xl font-semibold text-white">{t('payments.payment_options')}</h2>
                <p className="text-red-400 mt-4">{error}</p>
            </div>
        );
    }

    return (
        <PageContainer>
            <div className="bg-secondary p-6 shadow-lg rounded-md">
                <h2 className="text-xl font-semibold text-white">{t('payments.my_payment_options')}</h2>
                <div className="mt-6">
                    <ActionButton
                        label={t('payments.add_new_payment_method')}
                        onClick={handleAddPaymentMethod}
                        iconClass="fas fa-plus"
                    />
                </div>

                {paymentMethods.length > 0 ? (
                    <ul className="mt-4">
                        {paymentMethods.map((method) => (
                            <li
                                key={method.id}
                                className="flex justify-between items-center border-b border-gray-600 py-3"
                            >
                                <div className="text-white">
                                    <span className="font-semibold">
                                        {formatMethodType(method.method_type)}
                                    </span>
                                    {method.is_default && (
                                        <span className="ml-2 text-primaryAccent">
                                            ({t('payments.default')})
                                        </span>
                                    )}
                                    {method.method_type === 'credit_card' && (
                                        <div className="text-sm text-gray-400">
                                            **** **** **** {method.details.card_number.slice(-4)} <br />
                                            {t('payments.name_on_card')}:{' '}
                                            {method.details.name_on_card} <br />
                                            {t('payments.expiry')}:{' '}
                                            {method.details.expiry_date}
                                        </div>
                                    )}
                                    {method.method_type === 'paypal' && (
                                        <div className="text-sm text-gray-400">
                                            {t('payments.paypal_email')}:{' '}
                                            {method.details.paypal_email}
                                        </div>
                                    )}
                                    {method.method_type === 'bank_transfer' && (
                                        <div className="text-sm text-gray-400">
                                            {t('payments.bank')}:{' '}
                                            {method.details.bank_name} <br />
                                            {t('payments.account')}:{' '}
                                            {method.details.bank_account_number.slice(-4)} (
                                            {t('payments.last_four_digits')})
                                        </div>
                                    )}
                                </div>
                                <div className="flex space-x-2">
                                    <ActionButton
                                        onClick={() => handleDeletePaymentMethod(method.id)}
                                        label={
                                            isDeleting === method.id ? (
                                                <LoadingSpinner size="sm" />
                                            ) : (
                                                t('payments.delete')
                                            )
                                        }
                                        customStyles="bg-red-500 hover:bg-red-700 text-xs"
                                        disabled={isDeleting === method.id}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-white mt-4">{t('payments.no_payment_options')}</p>
                )}

                <PaymentMethodModal
                    isModalOpen={isModalOpen}
                    handleCloseModal={handleCloseModal}
                    newPaymentMethod={newPaymentMethod}
                    handleInputChange={handleInputChange}
                    handleMethodTypeChange={handleMethodTypeChange}
                    handleSetAsDefaultChange={handleSetAsDefaultChange}
                    setAsDefault={setAsDefault}
                    handleSubmitPaymentMethod={handleSubmitPaymentMethod}
                    serverError={serverError}
                    errors={errors}
                    setErrors={setErrors}
                />
            </div>
        </PageContainer>
    );
}

export default PaymentPage;
