import axiosInstance from '../axiosInstance';
import { BASE_URL } from '../config';

// Fetch payment methods
export const fetchPaymentMethods = async () => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/api/payment-methods/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Add a new payment method
export const addPaymentMethod = async (paymentData) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/api/add-payment-methods/`, paymentData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Delete a payment method
export const deletePaymentMethod = async (methodId) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/api/payment-methods/${methodId}/`);
        return response;
    } catch (error) {
        throw error;
    }
};
