import axiosInstance from '../axiosInstance';
import { BASE_URL } from '../config';

// Fetch counts for runners, videos, payment methods, and folders
export const fetchCounts = async () => {
    try {
        const response = await axiosInstance.get(`${BASE_URL}/api/get-count/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching counts:', error);
        throw error;
    }
};
