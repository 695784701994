import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
import { updateSettings, deleteAccount } from '../../services/settingsService';
import ActionButton from '../../components/utils/ActionButton';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import PageContainer from '../../components/layouts/PageContainer';
import SelectFieldDark from '../../components/utils/SelectFieldDark';


function SettingsPage() {
    const { t } = useTranslation();
    const { profile, logout, setProfile } = useContext(AppContext);
    const [language, setLanguage] = useState(profile.language_preference || 'en');
    const [heightUnit, setHeightUnit] = useState(profile.height_unit || 'cm');
    const [weightUnit, setWeightUnit] = useState(profile.weight_unit || 'kg');
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSaveSettings = async () => {
        setLoading(true);
        try {
            const updatedSettings = {
                language_preference: language,
                height_unit: heightUnit,
                weight_unit: weightUnit,
            };

            const response = await updateSettings(updatedSettings);

            // Update profile in context and local storage
            const updatedProfile = { ...profile, ...updatedSettings };
            setProfile(updatedProfile);
            localStorage.setItem('profile', JSON.stringify(updatedProfile));

            alert(t('settings.update_success'));
        } catch (error) {
            alert(t('settings.update_error'));
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteAccount = async () => {
        if (!showDeleteConfirm) {
            setShowDeleteConfirm(true);
            return;
        }

        try {
            const status = await deleteAccount();

            if (status === 200) {
                alert(t('settings.delete_success'));
                logout();
            } else {
                alert(t('settings.delete_failed'));
            }
        } catch (error) {
            alert(t('settings.delete_error'));
        }
    };

    const cancelDeleteAccount = () => {
        setShowDeleteConfirm(false);
    };

    return (
        <PageContainer>
            <div className="p-6 bg-secondary shadow-lg rounded-md">
                <h2 className="text-2xl font-semibold text-white mb-6">{t('settings.title')}</h2>
                <div className="space-y-6">
                    {/* Language Preference */}
                    <SelectFieldDark
                        label={t('settings.language')}
                        name="language"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        options={[
                            { value: 'en', label: t('settings.english') },
                            { value: 'es', label: t('settings.spanish') },
                            { value: 'fr', label: t('settings.french') },
                        ]}
                    />

                    {/* Height Unit */}
                    <SelectFieldDark
                        label={t('settings.height_unit')}
                        name="heightUnit"
                        value={heightUnit}
                        onChange={(e) => setHeightUnit(e.target.value)}
                        options={[
                            { value: 'cm', label: t('settings.centimeters') },
                            { value: 'in', label: t('settings.inches') },
                        ]}
                    />

                    {/* Weight Unit */}
                    <SelectFieldDark
                        label={t('settings.weight_unit')}
                        name="weightUnit"
                        value={weightUnit}
                        onChange={(e) => setWeightUnit(e.target.value)}
                        options={[
                            { value: 'kg', label: t('settings.kilograms') },
                            { value: 'lbs', label: t('settings.pounds') },
                        ]}
                    />

                    <div className="flex justify-end space-x-4 mt-8">
                        <ActionButton
                            onClick={handleSaveSettings}
                            label={loading ? <LoadingSpinner /> : t('settings.save')}
                            customStyles={`bg-[#4caf50] hover:bg-green-600 text-white flex items-center justify-center ${
                                loading ? 'cursor-not-allowed' : ''
                            }`}
                            disabled={loading}
                        />

                        {showDeleteConfirm ? (
                            <div className="flex items-center space-x-4 bg-primary text-white p-4 rounded-md">
                                <p>{t('settings.delete_confirm_message')}</p>
                                <div className="flex space-x-4">
                                    <ActionButton
                                        onClick={cancelDeleteAccount}
                                        label={t('settings.cancel')}
                                    />
                                    <ActionButton
                                        onClick={handleDeleteAccount}
                                        label={t('settings.confirm')}
                                        customStyles="bg-red-600 hover:bg-red-700 text-white"
                                    />
                                </div>
                            </div>
                        ) : (
                            <ActionButton
                                onClick={handleDeleteAccount}
                                label={t('settings.delete_account')}
                                customStyles="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-md"
                            />
                        )}
                    </div>
                </div>
            </div>
        </PageContainer>
    );
}

export default SettingsPage;
