import axios from 'axios';
import { BASE_URL } from '../config';

// Login API call
export const loginUser = async (email, password) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/api/auth/login/`,
            { email, password },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        if (error.response) {
            throw new Error(error.response.data.error || 'Login failed');
        }
        throw new Error('Error logging in');
    }
};




export const registerUser = async (email, password, isTrainer) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/api/auth/register/`,
            { email, password, is_trainer: isTrainer },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        if (error.response) {
            throw new Error(error.response.data.error || 'Registration failed');
        }
        throw new Error('Error registering user');
    }
};



// Service for resetting the password
export const resetPasswordConfirm = async (uidb64, token, newPassword, confirmPassword) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/api/auth/password-reset-confirm/${uidb64}/${token}/`,
            {
                new_password: newPassword,
                confirm_password: confirmPassword,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            throw new Error(error.response.data.error || 'Password reset failed');
        }
        throw new Error('An error occurred. Please try again later.');
    }
};


// Service for sending password reset request
export const requestPasswordReset = async (email) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/api/auth/password-reset/`,
            { email },
            {
                headers: { 'Content-Type': 'application/json' },
            }
        );
        return response.data; // Return response data for success message or additional info
    } catch (error) {
        if (error.response) {
            throw new Error(error.response.data.error || 'Failed to send password reset link');
        }
        throw new Error('An error occurred while sending the reset link. Please try again.');
    }
};
